import React, {Component} from 'react';
import apps from './our_apps'

const currentLocation = window.location.pathname.toLowerCase().replace(/\/+$/, "");

const makeBlurb = ({ appName = "Each of our apps", headerBlock = null }) => (
    <div>
      {headerBlock}
      <strong>Privacy Policy</strong> <p>
            {appName} is available as an Ad Supported Service. This Service is provided by
            Durian! Apps at no cost and is intended for use as is.
          </p> <p>
            This page is used to inform visitors regarding our
            policies with the collection, use, and disclosure of Personal
            Information if anyone decided to use our Service.
          </p> <p>
            If you choose to use our Service, then you agree to
            the collection and use of information in relation to this
            policy. The Personal Information that we collect is
            used for providing and improving the Service. We will not use or share your information with
            anyone except as described in this Privacy Policy.
          </p>
          <p><strong>Information Collection and Use</strong></p> <p>
            For a better experience, while using our Service, we
            may require you to provide us with certain personally
            identifiable information. The information that
            we request will be retained by us and used as described in this privacy policy.
          </p> <div><p>
              The app does use third party services that may collect
              information used to identify you.
            </p> <p>
              Link to privacy policy of third party service providers used
              by the app
            </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li></ul></div> <p><strong>Log Data</strong></p> <p>
            We want to inform you that whenever you
            use our Service, in a case of an error in the app
            we collect data and information (through third party
            products) on your phone called Log Data. This Log Data may
            include information such as your device Internet Protocol
            (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing our Service,
            the time and date of your use of the Service, and other
            statistics.
          </p> <p><strong>Cookies</strong></p> <p>
            Cookies are files with a small amount of data that are
            commonly used as anonymous unique identifiers. These are sent
            to your browser from the websites that you visit and are
            stored on your device's internal memory.
          </p> <p>
            This Service does not use these “cookies” explicitly. However,
            the app may use third party code and libraries that use
            “cookies” to collect information and improve their services.
            You have the option to either accept or refuse these cookies
            and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of this Service.
          </p> <p><strong>Service Providers</strong></p> <p>
            We may employ third-party companies and
            individuals due to the following reasons:
          </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
            We want to inform users of this Service
            that these third parties have access to your Personal
            Information. The reason is to perform the tasks assigned to
            them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
          </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
            We may update our Privacy Policy from
            time to time. Thus, you are advised to review this page
            periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on
            this page.
          </p> <p>This policy is effective as of 2021-03-21</p> <p><strong>Contact Us</strong></p> <p>
            If you have any questions or suggestions about our
            Privacy Policy, do not hesitate to contact us at contact@durianapps.com.
          </p>
        </div>
      
);

class PrivacyPolicy extends Component {
  render() {
    const curApp = apps.find(app => `/apps/${app.url}/privacy-policy` === currentLocation);

    if (curApp != null) {
      return makeBlurb({
        appName: `The ${curApp.name} app`,
        headerBlock: (<div className="InfoBox" style={{ marginBottom: 20 }}>
        <img src={`/${curApp.url}.png`} alt={curApp.name} style={{ paddingRight: 0, paddingLeft: 10 }} />
        <span style={{ fontStyle: "normal" }}>
          This page contains privacy policy info about our game {" "}<a href={`/apps/${curApp.url}`}>{curApp.name}</a>.
          <ul>
            <strong>In Summary:</strong>
            <li>This game runs directly on your device. <strong>We do not</strong> run or maintain any servers to power it, and no user info is collected by us directly.</li>
            <li><strong>We do</strong> utilize third-party services for analytics and advertising to support our development, and these services may store and collect information.</li>
          </ul>
          For more specific info on what services we use, <strong>continue reading</strong> below.
        </span>
      </div>)
      });
    }
  
    return makeBlurb({});
  }
}

export default PrivacyPolicy;