import React, {Component} from 'react';

const blurb = (
  <div className="formContainer">
    <h2>Gift Card Giveaway</h2>
    <p>Hello <a href="https://www.twitch.tv/brandon_barker">brandon_barker</a> follower! Please enter your Twitch username below to be entered to win up to five $10 Amazon gift cards at the end of the week!</p>
    <p>While you're here, check out our mobile games <a href="/apps/whoops">Whoops</a> and <a href="/apps/thinkbig">Think Big</a> for iOS and Android! Thanks!</p>
    <form action="https://formspree.io/mnqgrjdd" method="POST" className="form">
      <label>Twitch Username</label>
      <input type="text" name="name" placeholder="mikesmith1202"></input>
      <button type="submit">Submit!</button>
    </form>
  </div>
);

class Promotion extends Component {
  render() {
    return blurb;
  }
}

export default Promotion;