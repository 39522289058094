import React, {Component, Fragment} from 'react';

const RuleVideo = ({ app, name }) => (<p className="center">
<video width="320" height="240" poster={`/whoops/rules/${name}.png`} controls playsInline autoplay muted loop>
  <source src={`/${app}/rules/${name}.mp4`} type="video/mp4" />
  <img src={`/${app}/rules/${name}.png`} alt={`${app} ${name}`} />
</video>
</p>);

const ImageGallery = ({app, values}) => (
  <div className="ImageGallery">
    {values.map(val => <img src={`/${app}/rules/${val}.png`} alt={`${app} ${val}`} />)}
  </div>
);

const appRules = {
  airludo: (<div className="Rules">
    <div className="InfoBox">
      <img src='/airludo.png' alt="Air Ludo" />
      <span>This page contains rules about our game Air Ludo. For more info,{" "}<a href="/apps/airludo">click here</a>.</span>
    </div>
    <h2>Overview</h2>
    <p>This game is a digital version of <a href="https://en.wikipedia.org/wiki/Aeroplane_chess">Airplane Chess</a>, also called the airplane game, and similar to Ludo and Parchisi.</p> 
    <p>The goal is to move all 4 of your pieces from your starting area (in each of the four corners) to the center of the board.</p>
    <div style={{width: "100%", textAlign: "center"}}>
      <img src={"/airludo/rules/BoardPic.png"} alt="Air Ludo Board" width={350} />
    </div>
    <h2>Movement</h2>
    <p>Players take turns rolling the dice and moving their pieces according to the number rolled. The dice will roll on its own, before each player's turn.</p>
    <p><strong>Notice!</strong> To move a piece out of the starting area, a player must roll a 6. No other moves can be made until a 6 is rolled.</p>
    <p>Each piece must travel around the board clockwise, and land exactly on the center space to win. If a piece rolls higher and would land beyond the ending space, then they will spend the extra moves moving in reverse.</p>
    <ImageGallery app="airludo" values={["Blue1", "Red2", "Yellow3", "Green4"]} />
    <h2>Special Rules</h2>
    <p>Players can crash into each other, sending the crashed piece back to its starting area.</p>
    <p>Players can stack their pieces on top of each other to move as a group. If the group is crashed into, all pieces in the group are sent back to their starting areas.</p>
    <p>Players can take shortcuts to move around the board faster. These shortcuts are the colored zones on the board. A shortcut can only be used by a piece of the same color.</p>
    <h2>Gameplay</h2>
    <p>Play against friends locally or the computer. The current player is denoted by the color of the dice block, and the pieces that are able to move will pulse when it's your turn.</p>
    <p>If you are unable to make a move with the current rules on the dice, your turn is skipped. Don't worry, you'll get another chance next time!</p>
  </div>
  ),
  whoops: (<div className="Rules">
    <div className="InfoBox">
      <img src='/whoops.png' alt="Whoops" />
      <span>This page contains rules about our game Whoops. For more info,{" "}<a href="/apps/whoops">click here</a>.</span>
    </div>
    <h2>Overview</h2>
    <p>This game has similar play rules to other
    {" "}<a href="https://en.wikipedia.org/wiki/List_of_cross_and_circle_games">"cross and circle" board games</a>{" "}
    such as Ludo, Parchesi, and Sorry. The main goal is to move all 4 of your pieces clockwise around the board.</p> 
    <p>Your pieces will begin in the Start area, and make their way around the board into their corresponding Goal area.</p>
    <RuleVideo app="whoops" name="board" />
    <h2>Movement</h2>
    <p>Players take turns controlling their pieces according to the deck slider in the middle of the screen.
      The color of the flipped-over card shows whose turn it is.</p>
    <p>Each card has a different set of movement rules for that turn. These rules are summarized
      under the deck slider.</p>
    <RuleVideo app="whoops" name="cards" />
    <p>Most cards move your piece forwards the number on the card. Certain other cards have special rules,
      however, detailed further down this page.</p>
    <p>If you land on another player's piece after your move, their piece will go back to their starting area! Whoops!</p>
    <h2>The Cards</h2>
    The effects that each card has can be broken down into 7 categories. If a card is in more than one category,
    you can choose which effect to use.

    <p><b>Move from Start</b> (1, 2): Move a piece out of the Start area. This is the primary way to exit Start. Regular cards don't allow this.</p>
    <p><b>Move Forwards</b> (1, 2, 3, 5, 7, 8, 10, 11, 12): Most cards have this effect. Just move forwards the number of spaces on the card.</p>
    <p><b>Move Backwards</b> (4, 10): The 4 lets you move back 4 spaces, but the 10 only lets you move back 1 space.</p>
    <p><b>Move Again</b> (2): You are given another turn, and the deck slider will update to reflect this.</p>
    <p><b>Split Moves</b> (7): Divide moving forwards the number of spaces between two of your pieces.</p>
    <p><b>Swap Places</b> (11): Switch places with an opponent's piece. You will slide if you land on a slide zone.</p>
    <p><b>Whoops!</b> (W): Take a piece from Start, and move it to an opponent's piece. This bumps them back to Start!</p>
    <p>Sometimes, an effect won't be able to apply to your current scenario. These effects will be crossed out in the deck slider.</p>

    <ImageGallery app="whoops" values={["Blue", "Red", "Yellow", "Green"].map(v => `${v}card`)} />
    <p></p>
    <h2>Special Areas</h2>
    <p>In order to land in the Goal area, you must move the exact number of spaces onto the Goal.</p>
    <p>The 4 colored spaces before the Goal is the safety zone. Your pieces can't be Whoops'd here.</p>
    <p>Around the board are colored slide zones. If you land on a slide zone of a different color, you
      will slide through it, and send any pieces in the way (including your own!) back to Start.</p>
    <h2>Keep in Mind</h2>
    <p>The winner of the game is the first player who can bring all 4 of their pieces into their Goal area.</p>
    <p>If it's the first turn, or all your pieces are in Start, you will be allowed to move one piece out of home.
      This is regardless of whatever card you've drawn.</p>
    <p>If you are unable to make a move with the current rules on the drawn card, your turn is skipped.</p>
    <p>For the 7 card, there are some scenarios where the moves can't be split due to your other pieces being unable to move that many spaces after the first move.
    </p>
    <h2>Most Importantly</h2>
    <p>Have fun! In Germany, a similar game is titled <a href="https://en.wikipedia.org/wiki/Mensch_ärgere_Dich_nicht">Mensch ärgere Dich nicht</a>, which translates to "Man, don't get upset!"</p>
    <p>If you find yourself frustrated, and not in a good position, remember that your opponents will get theirs next time!</p>
  </div>),
  undefined: (<div>
    <p>We don't have any rules right now for an app with that name!</p>
    <p>See what apps we do have <a href="/apps">here</a>.</p>
    </div>)
};

const autoplayMagic = <script>
  {
    window.addEventListener('load', async () => {
      let video = document.querySelector('video[muted][autoplay]');
      try {
        await video.play();
      } catch (err) {
        video.controls = true;
      }
    })
  }
</script>

const currentLocation = window.location.pathname.toLowerCase().replace(/\/+$/, "");

class Rules extends Component {
  render() {
    // if there's no path, go right to /apps
    if (currentLocation === '/apps') {
      window.location.href="/apps";
    }

    // try to look up our current app by the location
    const curApp = Object.keys(appRules).find(name => `/rules/${name}` === currentLocation);

    return <Fragment>
      {appRules[curApp]}
      {autoplayMagic}
    </Fragment>;
  }
}

export default Rules;