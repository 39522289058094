import React from 'react';

// list of apps and URLs (icons and relative to this page)
const apps = [
  {
    name: "Whoops!!",
    url: "whoops",
    ios: "https://itunes.apple.com/us/app/whoops/id1289150061",
    android: "https://play.google.com/store/apps/details?id=com.durian.whoops",
    bio: (<div><p>This isn't your average board game! Whoops!! brings the fun of timeless "cross and circle" games to your tablet or phone. <a href="/rules/whoops">Click here for rules.</a></p>
    <p>Move around the board clockwise and try to get all four of your pieces into the ending area. Different cards influence your movement and enable you to perform special moves that can quickly turn the tides on your opponents. But be careful! Your opponents have access to the same set of moves.</p>
    <p>Gameplay is rapid-paced, as cards that contain invalid moves will slide off-screen automatically. No time is wasted in-between turns. Progress bars and ranks in the four corners of the screen will keep you up to date on where you stand in comparison to the other players.</p>
    <p>Your pieces have personality as well! Watch as they react to the events that occur around them whenever you make a move. Special cards can also activate cutscenes featuring your pieces going head-to-head with opponents!</p></div>)
  },
  {
    name: "Think Big",
    url: "thinkbig",
    ios: "https://itunes.apple.com/us/app/thinkbig/id1452073393",
    android: "https://play.google.com/store/apps/details?id=com.durianapps.landgrab",
    bio: (<div><p>Think Big is a game that challenges you to select the largest colored area as fast as possible. Choose one of the four regions before the timer in the upper right finishes counting down, then watch as each square disappears to reveal the winner.</p>
      <p>Continue guessing correctly to build your high score, then keep trying to beat your personal best!</p>
      <p>Think big, act fast, and choose wisely!</p></div>)
  },
  {
    name: "Air Ludo",
    url: "airludo",
    ios: "https://itunes.apple.com/us/app/airludo/id1476354529",
    android: "https://play.google.com/store/apps/details?id=com.durian.ludo",
    bio: (<div><p>Air Ludo is a digital version of the classic Chinese game Aeroplane Chess. <a href="/rules/airludo">Click here for rules.</a></p>
    <p>Crash into opponents, stack up with your other pieces, and take shortcuts to try to get around the board to make it to the center as fast as you can!</p>
    <p>Play against friends locally or the computer. The current player is denoted by the color of the dice block, and the pieces that are able to move will pulse when it's your turn.</p></div>)
  }
];

export default apps;
