import React, {Component} from 'react';
import apps from './our_apps';

const blurb = (
  <div className="formContainer">
    <h2>Have a Problem?</h2>
    <p>Let us know what's going on and we'll do our best to help. You can also send us an email directly at <a href="mailto:support@durianapps.com">support@durianapps.com</a></p>
    <form action="https://formspree.io/support@durianapps.com" method="POST" className="form">

      <label>Name</label>
      <input type="text" name="name" placeholder="Mike Smith"></input>
      <label>Email</label>
      <input type="email" name="email" placeholder="mikeys@gmail.com"></input>
      <input type="hidden" name="user_agent" value={window.navigator.userAgent}></input>
      <label>App</label>
      <select name="app">
        <option value="default">Choose an app...</option>
        {apps.map(app => (<option value={app.url}>{app.name}</option>))}
      </select>
      <label>Message</label>
      <textarea type="text" name="message" placeholder="Enter your message here..."></textarea>

      <button type="submit">Submit</button>
    </form>
  </div>
);

class Support extends Component {
  render() {
    return blurb;
  }
}

export default Support;