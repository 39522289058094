import React, {Component} from 'react';

const blurb = (
  <div>
    <h2>About Us</h2>
    <p>The Durian! brand was created alongside our first polished app, <a href="/apps/whoops">Whoops!!</a>. Our goal was to bring back nostalgic childhood gameplay experiences on modern devices. Later, we released <a href="/apps/airludo">Air Ludo</a> based on the same game engine, and eventually <a href="/apps/thinkbig">Think Big</a>.</p>
    <p>Our current efforts are focused on supporting our existing apps, by adding online services and incorporating user feedback. Please feel free to contact us via the <a href="/support">Support</a> page if you have any suggested changes for our apps.</p>
    <h2>What's a Durian?</h2>
    <p>Known as the "king of fruits", the durian is well known for its thorny exterior and strong scent. Beyond its rough outside, however, the fruit itself is very sweet.</p>
  </div>
);

class About extends Component {
  render() {
    return blurb;
  }
}

export default About;