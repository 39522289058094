import React, {Component} from 'react';
import apps from './our_apps'

const currentLocation = window.location.pathname.toLowerCase().replace(/\/+$/, "");

class AppInfo extends Component {
  render() {
    // if there's no path, go right to /apps
    if (currentLocation === '/apps') {
      window.location.href="/";
    }

    // try to look up our current app by the location
    const curApp = apps.find(app => `/apps/${app.url}` === currentLocation);

    if (curApp == null)
      return (<div>
        <p>We don't have an app that matches this URL!</p>
        <p>See what apps we do have <a href="/apps">here</a>.</p>
        </div>);

    return (<div>
      <h2>{curApp.name}</h2>
      <div className="AppDetails">
        <div className="IconsContainers">
          <div className="AppIconContainer">
            <img src={`/${curApp.url}.png`} alt={curApp.name} />
          </div>
          <div className="DownloadIcons">
            {curApp.ios && (<a target="_blank" rel="noopener noreferrer" href={curApp.ios}><img src="/app_store.png" alt="iOS App Store download link" /></a>)}
            {curApp.android && (<a target="_blank" rel="noopener noreferrer" href={curApp.android}><img src="/google_play.png" alt="Google Play download link" /></a>)}
          </div>
        </div>
        <div>
          {curApp.bio}
        </div>
      </div>
      <div className="Screenshots">
        {
          [1, 2].map(num => (<div className="imgWrapper"><img src={`/${curApp.url}/screen${num}.png`} alt="Screen shot" /></div>))
        }
      </div>
    </div>);
  }
}

export default AppInfo;
